import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";

interface PartnerCardProps {
    image: string;
    title: string;
  }
  
  const PartnerCard: React.FC<PartnerCardProps> = ({ image, title }) => (
    <Card
      sx={{
        minWidth: 270,
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <CardMedia
        component="img"
        height="140"
        sx={{ width: "90%", objectFit: "contain", margin: "auto" }}
        image={image}
        alt={`${title} Logo`}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{ color: "#012748", fontWeight: "bold", textAlign: "center" }}
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
  
  export default PartnerCard;