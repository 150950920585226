import React, { useRef, useState, useEffect } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Box,
    CssBaseline,
    Tab,
    Tabs,
    Button,
} from "@mui/material";
import { useMediaQuery, Theme } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import { TabContext} from "@mui/lab";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PartnerCard from "../PartnerCard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import JediHrLogo from "../../images/Jedi_HR_logo.webp";
import RaceTrackLogo from "../../images/rt-logo.png";
import TubbeesLogo from "../../images/tubbees-logo.png";
import vaped4uLogo from "../../images/vaped4u-logo.png";
import HoagiesLogo from "../../images/HoagiesLogo.png";
import NitroSolutionsLogo from "../../images/nitro-logo.svg";
import SpeedQueenLogo from "../../images/SpeedQueen.webp";
import "../styles/home.scss";


export default function Home() {
    const [value, setValue] = React.useState("1");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const homeRef = useRef<HTMLDivElement | null>(null);
    const aboutRef = useRef<HTMLDivElement | null>(null);
    const contactRef = useRef<HTMLDivElement | null>(null);
    
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        scrollToSection(newValue);
    };
    const scrollToSection = (section: string) => {
        const options: ScrollIntoViewOptions = {
            behavior: "smooth",
            block: "center", // Center the section on the screen
          };
        if (section === "1" && homeRef.current) {
            homeRef.current.scrollIntoView(options);
        } else if (section === "2" && aboutRef.current) {
            aboutRef.current.scrollIntoView(options);
        } else if (section === "3" && contactRef.current) {
            contactRef.current.scrollIntoView(options);
        }
    };

    useEffect(() => {
        const observerCallback = (entries: IntersectionObserverEntry[]) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (entry.target === homeRef.current) {
                setValue("1");
              } else if (entry.target === aboutRef.current) {
                setValue("2");
              } else if (entry.target === contactRef.current) {
                setValue("3");
              }
            }
          });
        };
    
        const observerOptions = {
          root: null,
          threshold: 0.8,
        };
    
        const observer = new IntersectionObserver(observerCallback, observerOptions);
    
        // Attach observer to each section
        if (homeRef.current) observer.observe(homeRef.current);
        if (aboutRef.current) observer.observe(aboutRef.current);
        if (contactRef.current) observer.observe(contactRef.current);
    
        // Cleanup observer on component unmount
        return () => {
          if (homeRef.current) observer.unobserve(homeRef.current);
          if (aboutRef.current) observer.unobserve(aboutRef.current);
          if (contactRef.current) observer.unobserve(contactRef.current);
        };
      }, []);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: !isMobile ?  (
            <ArrowForwardIosIcon sx={{color:"#0f4e6f", paddingRight:"3px","&:hover": {color: "#012748", backgroundColor:"#fede8c", borderRadius:"40px"}}}/>
        ) : undefined,
        prevArrow: !isMobile ? (
            <ArrowBackIosIcon sx={{color:"#0f4e6f", paddingLeft:"3px","&:hover": {color: "#012748", backgroundColor:"#fede8c", borderRadius:"40px"}}}/>
        ) : undefined,
        responsive: [
          { breakpoint: 1024, settings: { slidesToShow: 2 } },
          { breakpoint: 600, settings: { slidesToShow: 1 } },
        ],
      };

    return (
        <React.Fragment>
            <CssBaseline />
            {/* Header */}
            <AppBar
                position="sticky"
                elevation={0}
                sx={{
                    backgroundColor: "#012748",
                    color: "#fede8c",
                    borderBottom: "3px solid #fe9816",
                    padding: { xs: "9px", sm: "16px" },
                }}
            >
                <Toolbar sx={{ justifyContent: "space-between", alignItems: "center", flexDirection: { xs: "column", sm: "row" },}}>
                    {/* Logo */}
                    <Box 
                        sx={{ 
                            display: "flex", 
                            alignItems: "center" , 
                            padding:"6px", 
                            borderRadius:"6px",
                            backgroundColor: "rgba(255, 255, 255, 0.25)", 
                            marginBottom: { xs: "9px", sm: "3px" },
                            marginTop:"3px",
                        }}
                    >
                        <img
                        src={JediHrLogo}
                        alt="Jedi HR Solutions Logo"
                        style={{ 
                            width: isMobile ? "130px" : "150px",
                            height: "auto" 
                        }}
                        />
                    </Box>
                    {/* Navigation */}
                    <TabContext value={value}>
                        <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="inherit"
                        centered
                        sx={{
                            "& .MuiTab-root": {
                            color: "#fede8c",
                            fontWeight: "bold",
                            "&:hover": { color: "#fe9816" },
                            },
                            "& .Mui-selected": { color: "#fe9816" },
                        }}
                        >
                        <Tab label="Home" value="1" />
                        <Tab label="About" value="2" />
                        <Tab label="Contact Us" value="3" />
                        </Tabs>
                    </TabContext>
                </Toolbar>
            </AppBar>

            <div className="main-container">
            {/* Home Section */}
            <Box ref={homeRef} sx={{ position: "relative", height: "90vh", backgroundImage: "url('https://images.stockcake.com/public/b/7/e/b7e15997-aba1-484f-9c6d-870da53128b8_large/sunset-office-view-stockcake.jpg')", backgroundSize: "cover", backgroundPosition: "center", display: "flex", justifyContent: "center", alignItems: "center", color: "#ffffff" }}>
                <Box sx={{ position: "absolute", inset: 0, background: "rgba(0, 0, 0, 0.5)" }} />
                <Container sx={{ position: "relative", zIndex: 1, textAlign: "center", color: "#fff", px: { xs: 2, md: 6 }, width: "100%"  }}>
                    <Typography variant="h2" sx={{ fontWeight: "bold", fontSize: { xs: "2rem", md: "3.5rem" } }}>
                        Empowering Your HR Solutions
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 2, fontSize: { xs: "1rem", md: "1.5rem" } }}>
                        Jedi HR Solutions brings innovative strategies to streamline your business.
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                        mt: 4,
                        backgroundColor: "#fe9816",
                        color: "#fff",
                        padding: "10px 25px",
                        "&:hover": { backgroundColor: "#fede8c", color: "#012748" },
                        }}
                    >
                        Learn More
                    </Button>
                </Container>
            </Box>

            {/* Partners Section */}
            <Container sx={{ my: 14 }}>
                <Typography variant="h4" gutterBottom sx={{ textAlign: "center", fontWeight: "bold", color: "#012748" }}>
                    Our Trusted Partners
                </Typography>
                <Slider {...settings}>
                    <PartnerCard image={RaceTrackLogo} title="RaceTrack Pitstop" />
                    <PartnerCard image={TubbeesLogo} title="Tubbees Slushee" />
                    <PartnerCard image={vaped4uLogo} title="Vaped4U" />
                    <PartnerCard image={NitroSolutionsLogo} title="Nitro Solutions" />
                    <PartnerCard image={SpeedQueenLogo} title="Speed Queen" />
                    <PartnerCard image={HoagiesLogo} title="Hoagies" />
                </Slider>
            </Container>

            {/* About Section */}
            <Box ref={aboutRef} sx={{ py: 10, textAlign: "center", background: "linear-gradient(135deg, #fe9816, #fede8c)", borderRadius: 2, my: 14, marginLeft: 4, marginRight: 4 }}>
                <Container maxWidth="md">
                    <Typography variant="h3" sx={{ color: "#012748", fontWeight: "bold" }}>
                        About Jedi HR Solutions
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 4, color: "#012748", maxWidth: 700, margin: "auto", lineHeight: 1.8 }}>
                        At Jedi HR Solutions, we are dedicated to transforming your business with cutting-edge HR strategies. Our partnerships with top industry leaders enable us to provide tailored solutions that meet your unique needs. We believe in innovation, efficiency, and excellence.
                    </Typography>
                </Container>
            </Box>

            {/* Contact Section */}
            <Box ref={contactRef} sx={{ py: 10, textAlign: "center", background: "linear-gradient(135deg, #012748, #0f4e6f)", color: "#fede8c", borderRadius: 2, my: 14, marginLeft: 4, marginRight: 4 }}>
                <Container maxWidth="sm">
                    <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                        Contact Us
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 3, maxWidth: 500, margin: "auto", lineHeight: 1.6 }}>
                        Reach out to us for more information.
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 3, justifyContent: "center" }}>
                        <EmailIcon sx={{ marginRight: 1, color: "#fede8c" }} />
                        <a href="mailto:team@jedihrsolutions.co.uk" style={{ color: "#fede8c", textDecoration: "none" }}>
                            team@jedihrsolutions.co.uk
                        </a>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 3, justifyContent: "center" }}>
                        <CallIcon sx={{ marginRight: 1, color: "#fede8c" }} />
                        <a href="tel:01415301655" style={{ color: "#fede8c", textDecoration: "none" }}>
                            0141 530 1655
                        </a>
                    </Box>
                </Container>
            </Box>

            

            {/* Footer */}
            <Box
                sx={{
                py: 4,
                backgroundColor: "#012748",
                color: "#fede8c",
                textAlign: "center",
                }}
            >
                <Typography variant="body2">© 2024 Jedi HR Solutions.</Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                    Developed by{" "}
                    <a href="https://nitrosolutions.co.uk" target="_blank" style={{color: "#fede8c"}}>
                        Nitro Solutions
                    </a>
                </Typography>
            </Box>
            </div>
        </React.Fragment>
    );
}
